.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  text-align: center;
}

.col h2 {
  cursor: pointer;
}

.section {
  margin-top: 20px;
}

/* Styles for video consultation interface */
.video-call-interface {
  border: 1px solid #ccc;
  padding: 20px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.call-controls {
  margin-top: 10px;
}

.btn {
  margin-right: 10px;
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
}

.btn:hover {
  background-color: #0056b3;
}