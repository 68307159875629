
/* //===============================================videocallcss=================================== */

 .sCsSbKP9yxvw4LQAeaTz {
    color: #000;
    width: 100%;
    min-width: 430px;
    border-radius: 10px;
    max-height: 100vh;
} 


.Ubqx57BWwgO05T0rhxzR .Yb8CIZ4sfRB7tGNIe5vW {
    box-sizing: border-box;
    width: 302px;
    padding: 26px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 0px rgba(79, 79, 83, 0.2);
}


@media screen and (max-width: 1000px) {
    .sCsSbKP9yxvw4LQAeaTz {
        height: 45vh; 
    }
}

@media screen and (max-width: 900px) and (min-width: 783px) {
    .sCsSbKP9yxvw4LQAeaTz {
        width: 80% !important;

    }
}

.preconnectvideocall {
    color: #000;
    width: 100%;
    min-width: 430px;
    height: 84vh;
    border-radius: 10px;
   
}

.C7PnSkxqD6bOXhZlRlcc {
    position: absolute !important;
    top: 14px !important;
    right: 14px !important;
    z-index: 20 !important;
    width: 150px !important;
    height: 150px !important;
    border: 1px solid #3d4054 !important;
    border-radius: 10px !important;
}

.ji5jASszKFf2CGCmbxEh {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    padding: 0 30px;
    background: #1e2130;

}
.ji5jASszKFf2CGCmbxEh button {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.ji5jASszKFf2CGCmbxEh button:hover {
    background-color: #45a049;
   

}


.QbarJSxzhe6iPp6VdlAs {
    display: none !important;
}

.zego-video-container {
    border: 2px solid #4CAF50 !important;
    border-radius: 15px !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5) !important;
}

.zego-call-button {
    background-color: #007BFF !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    padding: 10px 20px !important;
}

.zego-text-chat {
    background-color: #333 !important;
    color: white !important;
    font-size: 14px !important;
    padding: 10px !important;
}

.BYpXSnOHfrC2td4QRijO {
    width: 100% !important;
    height: 100% !important;
}



.tkC3S6XQehKbTEIfFYVM {
    width: 100% !important;
    height: 100% !important;
}

.j9ygOVxEl2nClTPs77Ta {
    display: none !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
    background-color: #1c1f2e !important;
}

.tkC3S6XQehKbTEIfFYVM .lflaXazrPGAK9SbOcNoC {
    height: auto !important;
    text-align: center !important;
    font-weight: 400 !important;
    font-size: 26px !important;
    line-height: 37px !important;
}

.pVo6XoSfywa4eLk9ef2S {
    display: block;
    width: 100% !important;
    height: 100% !important;
    background: #242736;
    border-radius: 24px !important;
}

.full-video {
    display: block;
    width: 50% !important;
    position: absolute;
    top: 200px;
    left: 0;
    z-index: 9999;
   
}

.minimized-video {
    display: block;
    width: 200px !important;
    height: 150px !important;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    overflow: hidden;
}

.minimized-video {
    width: 200px;
    height: 100px;
    background-color: #e0e0e0;
    border: 2px solid #7CB2D0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    z-index: 1000; 
    overflow: hidden;
   
  }

  .minimized-video p {
    color: #ffffff; 
    text-align: center;
    font-size: 14px;
    padding: 30px 0; 
  }

@media screen and (min-width: 550px) and (max-width: 1000px) {
    .minimize-button {
        position: fixed;
        bottom: 480px;
        right: 495px;
        z-index: 10000;
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
    }
}


.minimize-button:hover {
    background-color: #0056b3;
}

.Ubqx57BWwgO05T0rhxzR {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(42, 42, 42, 0.5);
}

.Ubqx57BWwgO05T0rhxzR .Yb8CIZ4sfRB7tGNIe5vW {
    box-sizing: border-box;
    width: 302px;
    padding: 26px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 0px rgba(79, 79, 83, 0.2);
    z-index: 10001;
}

.incoming-call-box {
    position: absolute;
    /* top: 20%;
    left: 50%; */
    transform: translate(-50%, -20%);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 300px;
    width: 100%;
}


.incoming-call-box h3 {
    font-size: 1.5rem;
    color: #324e6a;
    margin-bottom: 15px;
}


.incoming-call-box button {
    display: inline-block;
    background-color: #46d459;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.incoming-call-box button:hover {
    background-color: #4a6d90;
}



.incoming-call-box button:nth-child(3) {
    background-color: #bb1111;
}

.incoming-call-box button:nth-child(3):hover {
    background-color: #e12b2b;
}


@media (max-width: 768px) {
    .incoming-call-box {
        top: 10%;
        max-width: 250px;
        padding: 15px;
    }

    .incoming-call-box h3 {
        font-size: 1.2rem;
    }

    .incoming-call-box button {
        padding: 8px 15px;
        font-size: 0.9rem;
    }
}

.homepage-container {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: rgb(209, 234, 241);
    height: 62vh;
}

@media screen and (max-width: 1000px) {
    .homepage-container {
        height: 45vh; 
        
    }
}

.content-container {
    text-align: center;
    width: 100%;
}

.incoming-call-box {
    background-color: #7CB2D0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.call-button {
    margin: 0 10px;
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.call-button:hover {
    background-color: #45a049;

}

.call-button.decline {
    background-color: #f44336;

}

.call-button.decline:hover {
    background-color: #e53935;

}

.initiate-call-container {
    margin-top: 20px;
}

.doctor-selection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.doctor-selection select {
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.initiate-call-button {
    padding: 10px 15px;
    background-color: #1976D2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.initiate-call-button:hover {
    background-color: #1976D2;
}
