.navbar-section {
    /* padding: 0 32px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-title a {
    color: #1A8EFD;
    letter-spacing: .6px;
    text-decoration: none;
}

.navbar-title {
    font-family: 'Poppins', sans-serif;
}

.navbar-sign {
    color: #54de54;
    font-family: Cambria, sans-serif;
    font-size: 40px;
    font-weight: bold;
}

.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: 'Rubik', sans-serif;
}

.navbar-items>li {
    list-style-type: none;
}

.history-icon {
    width: 36px;
    height: auto;
    cursor: pointer;
}

.hamb-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    cursor: pointer;
}

.navbar-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.navbar-btn:hover {
    color: #1A8EFD;
    background-color: white;
    border: 1px solid #1A8EFD;
}

/* .notification-box {
    position: absolute;
    top: calc(100% + 5px); 
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    display: none;
    z-index: 100;
  } */
.popup {
    position: fixed;
    top: 75px;
    /* Adjust this value to ensure it appears below the setting icon */
    right: 20px;
    background: rgba(255, 255, 255, 0.4);
    /* Slightly transparent white background */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    /* Initially hide the popup */
    z-index: 100;
    /* Set a high z-index to ensure it appears above other content */
    /* Add additional styling as needed */
}

.popup ul {
    list-style-type: none;
    /* Remove bullets from list items */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
}

.popup li:nth-child(1) {
    background-color: #324E6A;
    /* Background color for the first list item */
    color: white;
    /* Text color for the first list item */
    padding: 8px 12px;
    /* Adjust padding as needed */
    margin-bottom: 5px;
    /* Adjust margin between list items */
    border-radius: 5px;
    /* Apply border radius for rounded corners */
}

.popup li:nth-child(1) a {
    color: white;
    /* Text color for links in the first list item */
    text-decoration: none;
    /* Remove underline for links */
}

li a {
    color: #324E6A;
}

/* .notification-icon {
    
  }
  .notification-box.show {
    display: block; 
  }  */
.notification-box {
    position: absolute;
    top: 75px;
    /* Adjust this value to ensure it appears below the setting icon */
    right: 20px;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 100;
}

.notification-box ul {
    list-style-type: none;
    /* Remove bullets from list items */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
}

li {
    color: #324E6A;
}

/* Profile Popup Styling */
.profile-image-container {
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    /* Adjust as needed */
    border-radius: 50%;
    /* Make it circular */
    overflow: hidden;
    /* Hide overflow */
    background: #f0f0f0;
    /* Background color for placeholder */
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure image covers the circle */
}

.profile-image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    font-size: 14px;
    /* Adjust as needed */
    text-align: center;
}

.profile-popup {
    position: fixed;
    top: 75px;
    right: 20px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: none;
    max-height: 80vh;
    /* Maximum height of the popup */
    overflow-y: auto;
    /* Enable vertical scrollbar if content overflows */
}

/* Show Class for Visibility */
.profile-popup.show {
    display: block;
}

/* Doctor Details Styling */
.doctor-details p {
    margin: 10px 0;
    /* Space between paragraphs */
    font-size: 14px;
    /* Font size */
    color: #333;
    /* Text color */
}

.doctor-details strong {
    font-weight: bold;
    /* Make labels bold */
    color: #555;
    /* Slightly different color for labels */
}

/* Optional: Add a Close Button to the Popup */
.profile-popup .close-btn {
    position: absolute;
    /* Position relative to the popup */
    top: 10px;
    /* Position it inside the popup */
    right: 10px;
    /* Align it to the right side */
    background-color: transparent;
    /* Transparent background */
    border: none;
    /* No border */
    font-size: 16px;
    /* Font size */
    cursor: pointer;
    /* Cursor changes to pointer */
    color: #888;
    /* Color of the close button */
}

.profile-popup .close-btn:hover {
    color: #333;
    /* Darker color on hover */
}

/* Profile Popup Styling */
.profile-popup {
    position: fixed;
    top: 75px;
    right: 20px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: none;
}

/* Show Class for Visibility */
.profile-popup.show {
    display: block;
}

/* Doctor Details Styling */
.doctor-details p {
    margin: 10px 0;
    font-size: 14px;
    color: #333;
}

.doctor-details strong {
    font-weight: bold;
    color: #555;
}

.doctor-details input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    width: calc(100% - 20px);
    /* Full width minus padding */
    box-sizing: border-box;
    /* Include padding and border in width */
    margin-top: 5px;
    /* Space above input */
}

.doctor-details button {
    margin-top: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.doctor-details button:hover {
    background-color: #0056b3;
}

.doctor-details .cancel-btn {
    background-color: #6c757d;
}

.doctor-details .cancel-btn:hover {
    background-color: #5a6268;
}

.success-message-overlay {
    position: fixed;
    /* Ensures the message stays in place while scrolling */
    top: 10px;
    /* Position it at the top of the page */
    right: 10px;
    /* Align it to the right */
    background-color: #28a745;
    /* Green background for success */
    color: white;
    /* White text color */
    padding: 10px 20px;
    /* Padding for space around text */
    border-radius: 5px;
    /* Rounded corners */
    z-index: 1000;
    /* Ensures it overlays other content */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* Adds a subtle shadow for emphasis */
}
/* styles.css */
/* ----waris code 3mar 2025----- */
.telemedicin {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 35px;
    color: #1d94c4;
  }
 
@media (max-width: 767px) {
    .telemedicin {
      display: none;
    }
  }