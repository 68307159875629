* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.dashdoc-container {
  display: flex;
  flex-direction: column;
  background-image: url('../Assets/backgroud.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
}

.doctor-info-box {
  margin: 70px auto 0;
  width: 90%;
  max-width: 650px;
  background-color: #A6D4EE;
  padding: 20px;
  border-radius: 20px;
  color: white;
  z-index: 2;
  border: 2px solid #3981c4;
}

.cart-container {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
}

.cart {
  flex: 1 1 300px;
  max-width: 300px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #3981c4;
  opacity: 0.9;
}

.icon {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.cart .icon img {
  width: 60px;
  height: 60px;
}

.content p {
  font-size: 1em;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.red-icon {
  color: red;
}

.dark-blue-bg {
  background-color: #287b9c;
  color: white;
}
/* 
.doctor-image-container {
  display: none;
} */

@media (min-width: 768px) {
  .doctor-info-box {
    margin-left: 100px;
    margin-right: auto;
  }

  /* .doctor-image-container {
    display: block;
    position: absolute;
    top: 70px;
    right: 0;
    overflow: hidden;
  }

  .doctor-image {
    width: 400px;
    height: 400px;
    border-radius: 50%;
  } */

  .cart-container {
    margin-top: 30px;
    flex-wrap: nowrap;
  }

  .cart {
    flex: 1 0 21%;
  }
}

@media (max-width: 576px) {
  .doctor-info-box {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .cart-container {
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .cart {
    width: calc(100% - 20px);
    margin: 10px auto;
  }

  .icon {
    font-size: 2em;
  }

  .content p {
    font-size: 0.8em;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .doctor-info-box {
    margin-top: 150px;
    margin-left: 10%;
    margin-right: auto;
  }

  .cart-container {
    margin-top: 20px;
  }

  .cart:first-child {
    margin-left: 0;
  }
/* 
  .doctor-image-container {
    display: none;
  } */

  .cart {
    max-width: 200px;
    margin-top: 20px;
  }
}

@media (min-width: 585px) and (max-width: 913px) {
  .cart-container {
    margin-top: 20px;
  }
}

footer {
  background-color: #287b9c;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-top: auto;
} 
/* Dashboard.css
footer {
  background-color: #287b9c;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-top: auto;
} 

.dashboard-container {
  background: rgb(255, 255, 255);
  text-align: center;
  padding: 10px;
}
/* -----------waris code 21 march 2025------- */
/* HEADER SECTION */
.header-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #d0e0e5;
  padding: 30px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Doctor Info */
.doctor-info {
  text-align: left;
}

.welcome-text {
  background: #a3d4ed;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  color: #212529;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.doctor-name {
  font-size: 28px;
  font-weight: bold;
}

.tagline {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  text-align: center;
}

/* Doctor Image */
.doctor-image-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.doctor-image {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -30px;
  margin-bottom: -30px;
}

/* Appointment Card */
.appointment-card {
  background: #a3d4ed;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 200px;
}

.appointment-number {
  font-size: 36px;
  color: black;
  font-weight: bold;
}

/* ACTION BUTTONS */
.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  gap: 10px;
}

.action-button {
  background: #7cb2d0;
  color: white;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  width: 270px;
  height: 150px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background: #5a9ab7;
}

.iconhomedr {
  width: 150px;
  height: 150px;
  margin-top: -30px;
  margin-bottom: -20px;
}

/* 📱 MOBILE RESPONSIVENESS */
@media (max-width: 768px) {
  .header-section {
    flex-direction: column; /* Stack elements vertically */
    text-align: center;
  }

  .doctor-info {
    text-align: center;
  }

  .welcome-text {
    font-size: 18px;
    padding: 15px;
  }

  .doctor-name {
    font-size: 24px;
  }

  .tagline {
    font-size: 18px;
  }

  .doctor-image {
    width: 200px;
    height: 200px;
  }

  .appointment-card {
    width: 100%;
    max-width: 250px;
    margin-top: 20px;
  }

  .action-buttons {
    flex-direction: column;
    align-items: center;
  }

  .action-button {
    width: 90%;
    max-width: 300px;
    font-size: 14px;
  }

  .icon {
    width: 70px;
    height: 30px;
  }
}
