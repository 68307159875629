/* .homepage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #7CB2D0, #D0E0E5);
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: #ECECEC;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 0 20px;
    width: 100%;
}

.header-text {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
}

.buttons-container {
    display: flex;
    align-items: center;
}

.login-btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #0056b3;
}

.text-overlay {
    position: absolute;
    top: 35%;
    left: 40px;
    transform: translateY(-50%);
    color: white;
}

.text-overlay h1 {
    margin: 0;
    font-weight: bold;
    font-size: 36px;
}

.background-container {
    width: 100%;
    position: relative;
}

.home-background {
    width: 100%;
    height: auto;
    max-height: 50vh;
}

.card-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 100px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    background-color: #5f89b4;
}

.card {
    background-color: #0F60B3;
    border-radius: 10px;
    padding: 20px;
    width: calc(30% - 20px);
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-50px);
    opacity: 0;
    position: relative;
}

.card:hover {
    transform: translateY(-45px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
    padding: 20px;
}

.card h2 {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
}

.card p {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.card-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #a8d5e0;
}

.process-heading {
    text-align: center;
    margin-top: 40px;
}

.process-heading h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.process-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    background-color: #5385b1;
}

.process-card {
    background-color: #ECECEC;
    border-radius: 10px;
    padding: 20px;
    width: calc(30% - 20px);
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(-50px);
    opacity: 0;
}

.process-card:hover {
    transform: translateZ(50px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #a87991;
}

.number-container {
    position: absolute;
    top: -20px;
    transform: translateX(-50%);
    background-color: #0F60B3;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    padding: 10px 20px;
}

.process-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #0F60B3;
}

.process-card h2 {
    font-size: 24px;
    color: #0F60B3;
    margin-bottom: 10px;
}

.process-card p {
    font-size: 16px;
    color: #333;
}

.content-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.content-left {
    flex: 1;
    margin-right: 20px;
}

.content-left h1 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.content-left p {
    font-size: 18px;
    color: #666;
}

.content-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.query-section {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #ECECEC;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.query-box h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.query-box form {
    display: flex;
    flex-direction: column;
}

.query-box input,
.query-box textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.query-box button {
    padding: 10px;
    border: none;
    background-color: #0F60B3;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.query-box button:hover {
    background-color: #0d4b92;
}

.logo-image {
    height: 60px;
    width: auto;
    border-radius: 2px;
}

@media (max-width: 768px) {
    .card-container {
        flex-direction: column;
    }

    .card-container>* {
        flex-basis: 100%;
        margin-bottom: 20px;
    }
} */


.homepage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #7CB2D0, #D0E0E5);
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: #ECECEC;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
}

.header-text {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
}

.buttons-container {
    display: flex;
    align-items: center;
}

.login-btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #0056b3;
}

.text-overlay {
    position: absolute;
    top: 35%;
    left: 40px;
    transform: translateY(-50%);
    color: white;
}

.text-overlay h1 {
    margin: 0;
    font-weight: bold;
    font-size: 36px;
}

.background-container {
    width: 100%;
    position: relative;
}

.home-background {
    width: 100%;
    height: auto;
    max-height: 50vh;
    object-fit: cover;
}

.card-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 100px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    background-color: #5f89b4;
}

.card {
    background-color: #0F60B3;
    border-radius: 10px;
    padding: 20px;
    /* width: calc(30% - 20px); */
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-50px);
    opacity: 0;
    position: relative;
}

.card:hover {
    transform: translateY(-45px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
    padding: 20px;
}

.card h2 {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
}

.card p {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.card-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #a8d5e0;
}

.process-heading {
    text-align: center;
    margin-top: 40px;
}

.process-heading h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

/* .process-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    background-color: #5385b1;
}

.process-card {
    background-color: #ECECEC;
    border-radius: 10px;
    padding: 20px;
    width: calc(30% - 20px);
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(-50px);
    opacity: 0;
} */


.process-container {
    display: flex;
    justify-content: space-between;
    /* Ensure equal spacing between cards */
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #5385b1;
    flex-wrap: nowrap;
    /* Prevent wrapping to the next line */
}

.process-card {
    background-color: #ECECEC;
    border-radius: 10px;
    padding: 20px;
    width: 23%;
    /* Adjust width so four cards fit in a row */
    max-width: none;
    /* Remove the max-width restriction */
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(-50px);
    opacity: 0;
}


.process-card:hover {
    transform: translateZ(50px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #a87991;
}

.number-container {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #0F60B3;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    padding: 10px 20px;
}

.process-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #0F60B3;
}

.process-card h2 {
    font-size: 24px;
    color: #0F60B3;
    margin-bottom: 10px;
}

.process-card p {
    font-size: 16px;
    color: #333;
}

.content-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.content-left {
    flex: 1;
    margin-right: 20px;
}

.content-left h1 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.content-left p {
    font-size: 18px;
    color: #666;
}

.content-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.query-section {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #ECECEC;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.query-box h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.query-box form {
    display: flex;
    flex-direction: column;
}

.query-box input,
.query-box textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.query-box button {
    padding: 10px;
    border: none;
    background-color: #0F60B3;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.query-box button:hover {
    background-color: #0d4b92;
}

.logo-image {
    height: 80px;
    width: auto;
    border-radius: 2px;
}

/* Media Queries */
@media (max-width: 768px) {
    .header {
        padding: 0 10px;
    }

    .text-overlay h1 {
        font-size: 28px;
    }

    .card-container {
        flex-direction: column;
    }

    .process-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* Adjusts spacing between cards */
    }

    .process-card {
        flex: 1;
        margin: 10px;
        max-width: 200px;
        /* Adjust as necessary */
        text-align: center;
    }


    .content-container {
        flex-direction: column;
    }

    .content-left {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .content-right {
        align-items: flex-start;
    }

    .query-section {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .header-text {
        font-size: 20px;
    }

    .login-btn {
        font-size: 14px;
        padding: 8px 16px;
    }

    .text-overlay h1 {
        font-size: 24px;
    }

    .card h2 {
        font-size: 20px;
    }

    .card p {
        font-size: 16px;
    }

    .process-card h2 {
        font-size: 20px;
    }

    .process-card p {
        font-size: 14px;
    }

    .content-left h1 {
        font-size: 30px;
    }

    .content-left p {
        font-size: 16px;
    }

    .query-box h3 {
        font-size: 20px;
    }

    .query-box input,
    .query-box textarea {
        font-size: 14px;
    }

    .query-box button {
        font-size: 14px;
    }
}